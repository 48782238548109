<template>
<div ref="test" class="item-list-section">
  <v-row ref="testo">
    <v-col class="item-list-title" cols="12" sm="1">
      <div class=" text-center text-h6 text-lg-h5 font-weight-bold">#</div>
    </v-col>
    <v-col class="item-list-title" cols="12" sm="6">
      <div class="text-h6 text-lg-h5 font-weight-bold pl-1">Artículo</div>
    </v-col>
    <v-col class="item-list-title text-right" cols="12" sm="2">
      <div class="text-h6 text-lg-h5 font-weight-bold">P. Unit</div>
    </v-col>
    <v-col class="text-right item-list-title" cols="12" sm="2">
      <div class="text-h6 text-lg-h5 font-weight-bold">Total</div>
    </v-col>
    <v-col class="text-center item-list-title" cols="12" sm="1">
      <div class="text-h6 text-lg-h5 font-weight-bold"></div>
    </v-col>
  </v-row>
  <div id="itemsListContainer" style="height: 63vh; overflow: auto; overflow-x: hidden;">
    <div
      class="item-list-item"
      v-for="(item, index) in frontItemsList"
      v-bind:key="index">
      <item-list-item @clicked="openDialog($event)"
      :item="item" :index="index"></item-list-item>
    </div>
    <v-row class="border-separator"></v-row>
  </div>

  <!-- MODAL PARA EDITAR CANTIDAD -->
  <v-dialog class="edit-qty-modal" v-if="itemToPreview" v-model="dialog" max-width="500">
    <v-card>
      <v-card-title>Editar cantidad</v-card-title>
      <v-row class="pl-6 pr-6">
        <v-col cols="12" sm="12">
          <h3 class="item-name pb-4">{{itemToPreview.name}}</h3>
          <v-text-field outlined type="number"
          label="Cantidad"
          color="secondary"
          autofocus :rules="quantityRules"
          @keypress.enter="modifyQuantity"
          v-model="itemQuantity"></v-text-field>
          <v-btn :disabled="editQuantityBtnDisable" color="secondary"
            @click="modifyQuantity">Aceptar</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <!-- MODAL PARA EDITAR CANTIDAD -->
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemListItem from './ItemListItem.vue';

export default {
  name: 'item-list',
  components: {
    ItemListItem,
  },
  computed: {
    ...mapGetters({
      productsList: 'productsList',
      itemsList: 'itemsList',
      selectedClient: 'selectedClient',
      itemToPreview: 'itemToPreview',
      frontItemsList: 'frontItemsList',
    }),
    quantityRules() {
      if (this.ignoreInventory) {
        return [];
      }

      const rules = [];
      const rule = (v) => (v || 0) <= this.itemToPreview.inventoryQty
        || 'La cantidad es mayor que el inventario disponible';
      rules.push(rule);
      return rules;
    },
    editQuantityBtnDisable() {
      // disables button if not ignores inventory and quantity condition fails
      // else, if ignores inventory or quantity condition succeed, it passes
      return !this.ignoreInventory
        && (this.itemQuantity < 1 || this.itemQuantity > this.itemToPreview.inventoryQty);
    },
  },
  data: () => ({
    dialog: false,
    stompClient: null,
    itemQuantity: 0,
    ignoreInventory: false,
  }),
  methods: {
    openDialog(data) {
      this.itemQuantity = data?.quantity ?? 0;
      this.dialog = true;
    },
    clearItemsList() {
      this.$store.dispatch('clearItemsList');
    },
    scrollToItemsListBottom() {
      setTimeout(() => {
        const element = document.getElementById('itemsListContainer');
        element.scrollTop = (element.scrollHeight + 200);
      }, 50);
    },
    modifyQuantity() {
      // dont know why, but the enter event always enters here
      // this if prevents to continue if the button must be disabled
      if (this.editQuantityBtnDisable) {
        return;
      }

      let i = 0;
      const itemToAdd = this.itemsList.find((x) => x.id === this.itemToPreview.id);
      const sameItemsCount = this.itemsList.filter((x) => x.id === this.itemToPreview.id
        && x.isGift !== 1).length;
      const totalToAddRemove = this.itemQuantity - sameItemsCount;
      if (Math.sign(totalToAddRemove) === 1) {
        while (i < totalToAddRemove) {
          const payload = Object.assign(Object.create(Object.getPrototypeOf(itemToAdd)), itemToAdd);
          payload.isGift = 0;
          payload.comboId = 0;
          this.$store.dispatch('addItemToList', payload);
          i += 1;
        }
      } else if (Math.sign(totalToAddRemove) === -1) {
        const payload = { id: itemToAdd.id, quantity: Math.abs(totalToAddRemove) };
        this.$store.dispatch('removeFixedNumberItemsFromList', payload);
      }
      this.dialog = false;
      this.$store.dispatch('calculateCombos');
      this.itemQuantity = null;
    },
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 107 || event.keyCode === 187) {
        if (this.itemToPreview) {
          this.dialog = true;
        }
      }
    });
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'ADD_ITEM_TO_LIST') {
        // INFO: Cada vez que se agrega un articulo a la lista, correr el scroll hasta el fondo
        this.scrollToItemsListBottom();
      }
    });
    this.ignoreInventory = localStorage.getItem('ignoreInventory') === '1';
  },
};
</script>

<style lang="less" scoped>
.item-list-title {
  padding: 0;
  font-size: 1.4rem;
}
.item-name {
  font-weight: 400;
  font-size: 1.1rem;
}
.edit-qty-modal {
  overflow: hidden !important;
}
</style>
