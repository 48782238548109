<template>
  <v-container class="no-padding" fill-height fluid>
    <div class="blue-background"></div>
    <login-card></login-card>
  </v-container>
</template>

<script>
import LoginCard from '@/components/LoginCard.vue';

export default {
  name: 'login',
  components: {
    LoginCard,
  },
  mounted() {
    // clear storage, if at login, past login data must be deleted
    localStorage.clear();
  },
};
</script>

<style lang="less" scoped>
.no-padding {
  padding: 0;
}

.blue-background {
  width: 58.33%;
  height: 100vh;
  background-color: #0d1d4ee3;
  position: absolute;
  top: 0%;
  left: 41.6%;
}
</style>
