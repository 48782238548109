import Axios from '@/services/Axios';
import { ClientListItem } from '@/models/ClientListItem';

const resource = '/client';

export default {
  async getClientAutocompleteData(query = '') {
    const params = {
      query,
    };
    const response = await Axios.get(`${resource}/autocomplete`, { params });

    const mappedItems = response.data.map((element) => (
      new ClientListItem(element.id, element.name, element.discount)
    ));

    return [
      new ClientListItem(0, 'Anónimo', 0),
      ...mappedItems,
    ];
  },
};
