export class PurchaseOrderProduct {
  constructor(productId, currentCost, discount) {
    this.productId = productId;
    this.currentCost = currentCost;
    this.costDiscount = discount;
    this.isGift = 0;
    // this.discountPercentage = discountPe
    // this.discountType = discountType;
  }
}
