import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=3b7270a0&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=js"
export * from "./Payment.vue?vue&type=script&lang=js"
import style0 from "./Payment.vue?vue&type=style&index=0&id=3b7270a0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7270a0",
  null
  
)

export default component.exports