export class ClientListItem {
  constructor(id, name, discount) {
    this.id = id;
    this.name = name;
    this.discount = discount;
  }

  get autocompleteLabel() {
    return `${this.id} - ${this.name}`;
  }
}
