<template>
  <v-row>
    <v-col cols="12" sm="5" lg="6" class="pb-0">
      <v-autocomplete outlined
                      :items="clientsList"
                      v-model="selectedClient"
                      item-text="autocompleteLabel"
                      item-value="value"
                      :clearable="true"
                      return-object
                      @change="onClientSelected"
                      label="Cliente"
                      hide-details
                      no-data-text="No hay clientes"
                      color="secondary">
      </v-autocomplete>
      <p style="color:red; margin:0;" v-if="selectedClient && selectedClient.discount > 0">
        *Este cliente tiene descuento
      </p>
    </v-col>
    <v-col v-if="productsSearchType==='remote'" cols="12" sm="5" lg="4">
      <v-autocomplete
        ref="searchProductInput"
        outlined
        hide-details
        label="Buscar producto"
        color="secondary"
        no-data-text="No hay productos"
        v-model="selectedProduct"
        :items="productsList"
        :loading="isLoading"
        item-text="autocompleteLabel"
        item-value="value"
        :search-input.sync="query"
        :no-filter="true"
        @change="addItemToList"
        return-object
        @update:search-input="updateSearchInput">
        <template v-slot:item="{ item }">
            <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
            <v-list-item-subtitle>
              <span class="label">Marca: </span>
              <span v-html="item.brandName" class="mr-4"></span>
              <span class="label">Modelo: </span>
              <span v-html="item.model" class="mr-4"></span>
              <span class="label">Inventario: </span>
              <span v-html="item.inventoryQty"></span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-else cols="12" sm="5" lg="4">
      <v-autocomplete
        ref="searchProductInput"
        outlined
        hide-details
        label="Buscar producto"
        color="secondary"
        no-data-text="No hay productos"
        v-model="selectedProduct"
        :items="productsList"
        item-text="autocompleteLabel"
        item-value="value"
        :filter="customProductsFilter"
        @keypress.enter="addItemToList"
        @change="addItemToList"
        return-object>
      </v-autocomplete>
    </v-col>
    <v-col cols="3" sm="1">
      <v-btn v-if="stompClient && stompClient.connected"
             color="green"
             outlined
             fab>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn v-else
             @click="reconnectPrinter"
             color="red"
             outlined
             fab>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="3" sm="1">
      <v-btn title="Reimpresión de ticket"
             @click="reprintTicket()"
             color="primary"
             outlined
             fab>
        <v-icon>mdi-file-find</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import PurchaseOrderService from '@/services/PurchaseOrderService';
import { purchaseOrderToTicketData } from '@/serializers/PurchaseOrder.serializer';
import ProductService from '@/services/ProductService';

export default {
  name: 'pos-header',
  computed: {
    ...mapGetters({
      authUserData: 'authUserData',
      ticketStruct: 'ticketData',
      productsList: 'productsList',
      clientsList: 'clientsList',
      stompClient: 'stompClient',
      productsSearchType: 'productsSearchType',
    }),
  },
  data: () => ({
    isBarcodeScanned: false,
    selectedProduct: null,
    selectedClient: null,
    query: '',
    isLoading: false,
    ignoreInventory: false,
    searchProductTimeout: null,
  }),
  methods: {
    addItemToList() {
      const payload = Object.assign(
        Object.create(Object.getPrototypeOf(this.selectedProduct)),
        this.selectedProduct,
      );

      if (payload) {
        this.$store.dispatch('addItemToList', payload);
      }

      this.$nextTick(() => {
        this.selectedProduct = null;
        this.$store.dispatch('calculateCombos');
      });
    },
    // this is not implemented because we are using the same panel to sell
    // and to create quotations, so we can set items in cart with no limits
    // (but beware not to finish the sale)
    addItemToListWithInventoryValidation() {
      const { id, inventoryQty, quantity } = this.selectedProduct;
      const cartItems = this.$store.getters.frontItemsList;
      const productInCart = cartItems.find((item) => item.id === id);
      const futureQuantity = !!productInCart ? productInCart.quantity + quantity : quantity;
      this.ignoreInventory = localStorage.getItem('ignoreInventory') === '1';

      if (this.ignoreInventory || futureQuantity <= inventoryQty) {
        const payload = Object.assign(
          Object.create(Object.getPrototypeOf(this.selectedProduct)),
          this.selectedProduct,
        );

        if (payload) {
          this.$store.dispatch('addItemToList', payload);
        }

        this.$nextTick(() => {
          this.selectedProduct = null;
          this.$store.dispatch('calculateCombos');
        });
      } else {
        Swal.fire('Error', 'El producto no cuenta con mercancia en inventario', 'error');
        this.$nextTick(() => {
          this.selectedProduct = null;
        });
      }
    },
    onClientSelected() {
      this.$store.dispatch('selectClient', this.selectedClient);

      // search new products with client
      this.onSearchProducts();
    },
    customProductsFilter(item, queryText) {
      const searchKeyWords = queryText.split(' ');
      let keywordMatches = true;
      const itemName = item.name.toUpperCase();
      const itemBrand = item.brandName.toUpperCase();
      const itemModel = item.model ? item.model.toUpperCase() : '';
      const itemKeys = item.keys ? item.keys.toUpperCase() : '';
      let fullString = `${itemName} ${itemBrand} ${itemModel} ${itemKeys}`;
      fullString = fullString.toUpperCase();
      searchKeyWords.forEach((keyWord) => {
        if (keyWord !== '') {
          const upperKeyword = keyWord.toUpperCase();
          if (!fullString.includes(upperKeyword)) {
            keywordMatches = false;
          }
        }
      });
      return keywordMatches;
    },
    reconnectPrinter() {
      this.$store.dispatch('createWebSocket');
    },
    async reprintTicket() {
      if (!this.stompClient || !this.stompClient.connected) {
        // todo: show message
        return;
      }

      const result = await Swal.fire({
        title: 'Re-imprimir ticket',
        input: 'text',
        confirmButtonText: 'Imprimir',
        text: 'Número de orden de compra',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed && !Number.isNaN(Number(result.value))) {
        const id = Number(result.value);
        const data = await PurchaseOrderService.getPurchaseOrderData(id);

        if (this.stompClient && this.stompClient.connected) {
          const ticketData = purchaseOrderToTicketData(data, this.ticketStruct);
          const payload = JSON.stringify(ticketData);

          // eslint-disable-next-line max-len
          this.stompClient.send('/exchange/orders/orders', payload, { 'content-type': 'application/json' });
        }
      }
    },
    async sleep(_ms) {
      return new Promise((resolve) => setTimeout(resolve, _ms));
    },
    updateSearchInput(query) {
      clearTimeout(this.searchProductTimeout);

      if (query != null && query.length > 2) {
        this.searchProductTimeout = setTimeout(() => {
          this.isLoading = true;
          this.searchProduct(query);
        }, 500);
      }
    },
    async onSearchProducts() {
      this.isLoading = true;

      if (this.isBarcodeScanned) {
        this.query = '';
        this.isBarcodeScanned = false;
        this.isLoading = false;
        return;
      }

      await this.searchProduct(this.query);
      // this.$refs.searchProductInput.focus();
    },
    async searchProduct(query) {
      const clientId = this.selectedClient?.id ?? null;
      const limit = this.productsSearchType === 'remote' ? 100 : 100;

      const { store } = this.authUserData.user_claims;
      const response = await ProductService.getProductAutocompleteData(
        query,
        limit,
        store,
        clientId,
      );

      if (query && query.match(/^\d+$/i) && response.length === 1) {
        // barcode process
        [this.selectedProduct] = response;
        this.addItemToList();
      } else {
        this.$store.dispatch('setProductsAutocomplete', response);
      }

      // eslint-disable-next-line no-return-assign
      this.isLoading = false;
      // setTimeout(() => (this.isLoading = false), 300);
    },
    onBarcodeScanned(value) {
      this.isBarcodeScanned = true;
      this.searchProduct(value);
    },
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'CLEAR_ORDER') {
        this.selectedClient = null;
      }
    });

    const barcodeSensitivity = localStorage.getItem('barcodeSensitivity') ?? 100;
    this.$barcodeScanner.setSensitivity(barcodeSensitivity);
  },
  created() {
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    this.$barcodeScanner.destroy();
  },
};
</script>

<style lang="less" scoped>
.v-text-field__details {
  display: none !important;
}

.v-list-item__mask {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.bullet-point {
  font-size: 50px;
}
</style>
