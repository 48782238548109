<template>
  <v-row class="total-payment-section">
    <div v-if="block === 1">
      <v-overlay>
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <v-col cols="12" lg="8" class="pb-0">
      <v-col cols="12" class="total-payment-label">
        <div class="text-center text-h6 text-lg-h5">Total a Pagar</div>
      </v-col>
      <v-col cols="12" class="text-center total-payment">
        <h1 class="">{{ cartTotal.FTotal }}</h1>
      </v-col>
    </v-col>
    <v-col cols="12" lg="4" align-self="center" class="pr-0 pl-0" v-if="canViewPos">
      <v-btn v-if="itemsList.length > 0"
             @click="startPayment"
             block
             color="secondary"
             class="d-flex d-lg-none">
        PAGAR
      </v-btn>
      <v-btn v-if="itemsList.length > 0"
             @click="startPayment"
             color="secondary"
             x-large
             class="d-none d-lg-flex">
        PAGAR
      </v-btn>
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-toolbar flat>
          <v-btn icon @click="returnToOrder">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Registrar pago</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <h2 class="total-section mb-2">Total a pagar: <b>{{ cartTotal.FTotal }}</b></h2>
          <h2 class="total-section mb-2">Total pagado: <b>{{ FTotalOrderPaid }}</b></h2>
          <h2 class="total-section mb-2">Total restante: <b>{{ FRemainingTotal }}</b></h2>
          <h2 class="total-section">Cambio del cliente: <b>{{ FChange }}</b></h2>
          <br/>
          <div v-if="remainingTotal > 0">
            <h2 class="mt-2 mb-2" v-if="selectedPayment < 1 && !disabledBtn">
              Selecciona un método de pago
            </h2>
            <v-row v-if="selectedPayment < 1 && !disabledBtn">
              <v-col cols="12" sm="6">
                <v-btn @click="selectedPayment = 1"
                       block dark color="lightPrimary">
                  <v-icon class="mr-2">mdi-cash</v-icon>
                  Efectivo
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn :disabled="disabledBtn" @click="selectedPayment = 1.5"
                       block dark color="lightPrimary">
                  <v-icon class="mr-2">mdi-credit-card</v-icon>
                  Tarjeta
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn :disabled="disabledBtn" @click="selectedPayment = 4"
                       block dark color="lightPrimary">
                  <v-icon class="mr-2">mdi-checkbook</v-icon>
                  Cheque
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn :disabled="disabledBtn" @click="selectedPayment = 5"
                       block dark color="lightPrimary">
                  <v-icon class="mr-2">mdi-transfer-right</v-icon>
                  Transferencia
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="selectedPayment === 1">
              <v-col cols="12" sm="12">
                <v-currency-field v-model="moneyImport" outlined prepend-icon="mdi-cash"
                                  @keypress.enter="makeTransaction"
                                  @input="getChange" color="secondary" label="Importe efectivo">
                </v-currency-field>
              </v-col>
              <h2>Cambio del cliente: {{ transactionChange }}</h2>
            </v-row>

            <v-row v-if="selectedPayment === 1.5">
              <v-col cols="12" sm="12">
                <v-radio-group v-model="cardType" row>
                  <v-radio color="secondary" label="Tarjeta de débito" value=2></v-radio>
                  <v-radio color="secondary" label="Tarjeta de crédito" value=3></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="12">
                <v-currency-field v-model="moneyImport"
                                  @keypress.enter="makeTransaction"
                                  outlined prepend-icon="mdi-credit-card"
                                  class="pb-4"
                                  color="secondary" label="Importe tarjeta">
                </v-currency-field>
                <v-text-field v-model="cardLastNumbers" type="number"
                              @keypress.enter="makeTransaction"
                              outlined prepend-icon="mdi-numeric"
                              class="pb-4"
                              color="secondary" label="Últimos 4 dígitos de la tarjeta">
                </v-text-field>
                <v-text-field v-model="confirmationNumber" type="number"
                              @keypress.enter="makeTransaction"
                              outlined prepend-icon="mdi-shield-check-outline"
                              color="secondary" label="Número de confirmación">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="selectedPayment === 4">
              <v-col cols="12" sm="12">
                <v-currency-field v-model="moneyImport"
                                  @keypress.enter="makeTransaction"
                                  outlined prepend-icon="mdi-checkbook"
                                  class="pb-4"
                                  color="secondary" label="Importe cheque">
                </v-currency-field>
                <v-text-field v-model="bankName"
                              @keypress.enter="makeTransaction"
                              outlined prepend-icon="mdi-bank"
                              class="pb-4"
                              color="secondary" label="Nombre del banco"></v-text-field>
                <v-text-field v-model="confirmationNumber" type="number"
                              @keypress.enter="makeTransaction"
                              outlined prepend-icon="mdi-shield-check-outline"
                              color="secondary" label="Número de confirmación">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="selectedPayment === 5">
              <v-col cols="12" sm="12">
                <v-currency-field v-model="moneyImport"
                                  @keypress.enter="makeTransaction"
                                  class="pb-4"
                                  outlined prepend-icon="mdi-transfer-right"
                                  color="secondary" label="Importe transferencia">
                </v-currency-field>
                <v-text-field v-model="confirmationNumber" type="number"
                              @keypress.enter="makeTransaction"
                              outlined prepend-icon="mdi-shield-check-outline"
                              color="secondary" label="Número de confirmación">
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions v-if="selectedPayment > 0">
          <v-spacer></v-spacer>
          <v-btn @click="clearPayment" color="error">Regresar</v-btn>
          <v-btn color="secondary" @click="makeTransaction">
            Terminar pago
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="returnToOrder" color="error">Regresar</v-btn> -->
          <v-btn v-if="block === 0 && payments.length > 0 || realPaidCash" color="error"
                 @click="clearPayments">
            Eliminar pagos
          </v-btn>
          <v-btn v-if="remainingTotal <= 0 && block === 0" @click="finishOrder" color="secondary">
            Terminar orden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// TODO: OBTENER ALGUNA ORDEN QUE NO SE HAYA CERRADO
// (POR QUE SE CERRO LA PAGINA, SE APAGO LA PC ETC) Y MOSTRAR EL MODAL DE PAGO PARA TERMINAR EL PAGO
// import Quotation from '@/components/POS/Quotation.vue';
import Swal from 'sweetalert2';
import { purchaseOrderToTicketData } from '@/serializers/PurchaseOrder.serializer';
import { mapGetters } from 'vuex';
import { FPUtils } from '@/utils/FloatingPointUtils';
import { PurchaseOrderProduct } from '@/models/PurchaseOrder/PurchaseOrderProduct';
import PurchaseOrderService from '../../services/PurchaseOrderService';
import ProductService from '../../services/ProductService';
import CurrencyFormatter from '../../models/CurrencyFormatter';

export default {
  name: 'payment',
  computed: {
    ...mapGetters({
      cartTotal: 'cartTotal',
      authUserData: 'authUserData',
      ticketStruct: 'ticketData',
      itemsList: 'itemsList',
      selectedClient: 'selectedClient',
      totalOrderPaid: 'totalOrderPaid',
      FTotalOrderPaid: 'FTotalOrderPaid',
      stompClient: 'stompClient',
      frontItemsList: 'frontItemsList',
      savedQuotationPurchaseOrderId: 'savedQuotationPurchaseOrderId',
      canViewPos: 'canViewPos',
    }),
    remainingTotal() {
      return FPUtils.round2dec(this.cartTotal.total - this.totalOrderPaid);
    },
    FRemainingTotal() {
      if (this.remainingTotal > 0) {
        return CurrencyFormatter.format(this.remainingTotal);
      }
      return CurrencyFormatter.format(0);
    },
    cashChange() {
      if (this.remainingTotal > 0) {
        const change = this.realPaidCash - this.remainingTotal;
        if (change > 0) {
          return change;
        }
      }
      if (this.realPaidCash > 0) {
        return Math.abs(this.remainingTotal);
      }
      return 0;
    },
    FChange() {
      if (this.remainingTotal > 0) {
        const change = this.realPaidCash - this.remainingTotal;
        if (change > 0) {
          return CurrencyFormatter.format(change);
        }
      }
      if (this.realPaidCash > 0) {
        return CurrencyFormatter.format(Math.abs(this.remainingTotal));
      }
      return CurrencyFormatter.format(0);
    },
    disabledBtn() {
      return !this.remainingTotal > 0;
    },
  },
  data: () => ({
    dialog: false,
    transactionChange: 0,
    moneyImport: null,
    swalText: '¿Desea cerrar la orden y proceder al pago?',
    selectedPayment: 0,
    cardType: 0,
    cardLastNumbers: null,
    confirmationNumber: null,
    bankName: null,
    payments: [],
    change: 0,
    realPaidCash: 0,
    block: 0,
  }),
  methods: {
    startPayment() {
      this.payments = [];
      this.realPaidCash = 0;
      this.moneyImport = null;
      this.dialog = true;
    },
    async makeTransaction() {
      let paymentMethod = 0;
      const total = this.remainingTotal;
      const totalImport = this.moneyImport;

      if (this.selectedPayment === 1) {
        this.realPaidCash += this.moneyImport;
        this.$store.dispatch('updateTotalOrderPaid', this.moneyImport);
        this.clearPayment();
        return;
      }
      if (this.selectedPayment === 1.5) {
        if (!this.cardType || this.cardType < 2) {
          Swal.fire('Error', 'Selecciona un tipo de tarjeta antes de continuar', 'error');
          return;
        }
        paymentMethod = this.cardType;
      } else {
        paymentMethod = this.selectedPayment;
      }

      if (this.moneyImport > total) {
        Swal.fire('Error', 'El importe no puede ser mayor al total restante en metodos de pago que no sean efectivo', 'error');
        return;
      }
      this.payments.push({
        totalImport,
        paymentMethod,
        confirmationNumber: this.confirmationNumber,
        cardLastDigits: this.cardLastNumbers,
        bankName: this.bankName,
      });

      // this.$store.dispatch('updateTotalOrderPaid', totalImport);
      this.$store.dispatch('updateTotalOrderPaid', this.moneyImport);
      this.clearPayment();
    },
    async finishOrder() {
      const ignoreInventory = localStorage.getItem('ignoreInventory') === '1';
      const productsTotal = [];
      const products = [];
      this.block = 1;
      this.itemsList.forEach((x) => {
        const product = new PurchaseOrderProduct(x.id, x.baseCost, x.calculatedDiscount);
        product.isGift = x.isGift;
        products.push(product);
      });

      this.frontItemsList.forEach((item) => {
        if (item.isGift !== 1) {
          productsTotal.push({ productId: item.id, total: item.quantity });
        }
        // todo: why?
        /*
        if (item.isGift === 1) {
          for (let i = 0; i < item.quantity; i += 1) {
            let lastItem = products.filter((x) => x.productId === item.id && x.isGift !== 1);
            lastItem = lastItem[lastItem.length - 1];
            lastItem.isGift = 1;
          }
        }
        */
      });

      if (this.realPaidCash && this.realPaidCash > 0
        && this.payments.filter((x) => x.paymentMethod === 1).length === 0) {
        this.payments.push({
          totalImport: this.realPaidCash,
          paymentMethod: 1,
          confirmationNumber: null,
          cardLastDigits: null,
          bankName: null,
          change: this.cashChange,
        });
      }

      if (this.remainingTotal <= 0) {
        const { total } = this.cartTotal;
        const employeeId = this.authUserData.user_claims.employee;
        let clientId;
        let clientDiscount = 0;

        if (this.selectedClient && this.selectedClient.id > 0) {
          clientId = this.selectedClient.id;
          clientDiscount = this.selectedClient.discount;
        }

        let resp = null;
        // eslint-disable-next-line max-len
        resp = await PurchaseOrderService.finishPurchaseOrder(employeeId, clientId, clientDiscount, total, this.payments, products, productsTotal, ignoreInventory ? 1 : 0);
        if (resp && resp.error) {
          Swal.fire('Error', 'Hubo un error al registrar los pagos', 'error');
          this.block = 0;
          return;
        }
        if (this.savedQuotationPurchaseOrderId && this.savedQuotationPurchaseOrderId > 0) {
          const quotationOrderId = this.savedQuotationPurchaseOrderId;
          const response = await PurchaseOrderService.finishSavedPurchaseOrder(quotationOrderId);
          if (response && response.error) {
            Swal.fire('Error', 'Hubo un error al registrar los pagos', 'error');
            this.block = 0;
            return;
          }
          this.$store.dispatch('clearSavedQuotationItems');
        }

        // print ticket
        await this.printTicket(resp.data.purchaseOrderId);

        // reset data
        this.payments = [];
        this.$store.dispatch('clearOrder');
        this.$store.dispatch('clearTotalOrderPaid');
        this.dialog = false;

        // request products again
        const { store } = this.authUserData.user_claims;
        if (this.productsSearchType === 'remote') {
          const payload = await ProductService.getProductAutocompleteData('', 100, store, null);
          this.$store.dispatch('setProductsAutocomplete', payload);
        } else {
          const payload = await ProductService.getProductAutocompleteData('', 100, store, null);
          this.$store.dispatch('setProductsAutocomplete', payload);
        }
        Swal.fire('Correcto', 'Se guardaron los pagos y se finalizó la orden', 'success');
      } else {
        Swal.fire('Error', 'No se ha pagado la orden', 'error');
      }
      this.block = 0;
    },
    clearPayment() {
      this.selectedPayment = 0;
      this.moneyImport = 0;
      this.moneyImport = null;
      this.moneyImport = null;
      this.cardType = null;
      this.transactionChange = '$0.00';
      this.cardLastNumbers = null;
      this.confirmationNumber = null;
      this.bankName = null;
    },
    getChange() {
      const total = this.cartTotal.total - this.totalOrderPaid;
      let change = parseFloat(this.moneyImport) - parseFloat(total);
      if (change < 0) {
        change = 0;
      }
      this.transactionChange = CurrencyFormatter.format(change);
      this.change = change;
    },
    returnToOrder() {
      if (this.payments.length > 0 || this.realPaidCash) {
        const swal = Swal.fire({
          allowOutsideClick: false,
          title: 'Regresar a orden',
          confirmButtonText: 'Si',
          text: '¿Desea regresar a la orden? Se eliminarán los pagos realizados',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
        });
        swal.then((resp) => {
          if (resp.value) {
            this.payments = [];
            this.$store.dispatch('clearTotalOrderPaid');
            this.dialog = false;
            this.realPaidCash = 0;
          }
        });
      } else {
        this.payments = [];
        this.$store.dispatch('clearTotalOrderPaid');
        this.dialog = false;
        this.realPaidCash = 0;
      }
    },
    clearPayments() {
      const swal = Swal.fire({
        allowOutsideClick: false,
        title: 'Eliminar pagos',
        confirmButtonText: 'Si',
        text: '¿Seguro que desea eliminar los pagos realizados?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
      });
      swal.then((resp) => {
        if (resp.value) {
          this.payments = [];
          this.realPaidCash = 0;
          this.change = 0;
          this.transactionChange = 0;
          this.$store.dispatch('clearTotalOrderPaid');
        }
      });
    },
    async printTicket(id) {
      const data = await PurchaseOrderService.getPurchaseOrderData(id);
      if (this.stompClient && this.stompClient.connected) {
        const ticketData = purchaseOrderToTicketData(data, this.ticketStruct);
        const payload = JSON.stringify(ticketData);
        this.stompClient.send('/exchange/orders/orders', payload, { 'content-type': 'application/json' });
      } else {
        // eslint-disable-next-line no-console
        console.error('no print client connected');
      }
    },
    mounted() {
    },
  },
};
</script>

<style lang="less" scoped>
.total-payment-section {
  padding-top: 2rem;
}

.total-payment-label {
  padding: 0;

  h2 {
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.total-payment {
  padding: 0;

  h1 {
    font-weight: 400;
    font-size: 2.6rem;
  }
}

.total-section {
  font-weight: 400;
}
</style>
