import Axios from '@/services/Axios';
import { ProductListItem } from '@/models/ProductListItem';

const resource = '/product';

export default {
  async getProductAutocompleteData(query, limit, storeId, clientId) {
    const params = {
      query,
      limit,
      storeId,
      clientId,
    };

    let response = null;
    response = await Axios.get(`${resource}/autocomplete`, { params });
    response = response.data;

    return response.map((element) => (
      new ProductListItem(
        element.id,
        element.name,
        element.barcode,
        element.baseCost,
        element.images,
        element.discountSpecials,
        element.comboSpecials,
        element.model,
        element.brand,
        element.keys,
        element.inventoryProduct[0],
      )
    ));
  },
};
