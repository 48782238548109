<template>
  <v-container class="no-padding global-container" fluid>
    <v-row>
      <pos-header></pos-header>
    </v-row>
    <v-row>
      <v-col cols="4" lg="3">
        <h2 v-if="!itemsListCount" class="total-items-label font-weight-bold">
          No hay artículos en el carrito.
        </h2>
        <h2 v-else class="total-items-label">
          Total de artículos en el carrito: <b>{{ itemsListCount }}</b>
        </h2>
      </v-col>
      <v-col cols="3">
        <v-btn v-if="!showSavedQuotationsAutocomplete"
               @click="showSavedQuotationsAutocomplete = true" color="info">
          <v-icon>mdi-magnify</v-icon>
          cotización
        </v-btn>
        <v-autocomplete v-if="showSavedQuotationsAutocomplete"
                        :items="savedQuotations"
                        color="secondary"
                        v-model="selectedSavedQuotation"
                        class="saved-quotations-ac"
                        @change="getSavedQuotationData"
                        no-data-text="No hay cotizaciones guardadas"
                        item-value="id" item-text="foil" outlined></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="3">
        <quotation></quotation>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" class="pa-0">
        <item-list ref="itemlist"></item-list>
      </v-col>
      <v-col cols="12" md="4" class="pa-1">
        <v-col cols="12" md="12" style="height: 50vh;">
          <product-info></product-info>
        </v-col>
        <v-col cols="12" md="12" class="pr-8">
          <payment></payment>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Quotation from '@/components/POS/Quotation.vue';
import Payment from '@/components/POS/Payment.vue';
import PosHeader from '@/components/POS/PosHeader.vue';
import ItemList from '@/components/POS/ItemList.vue';
import ProductInfo from '@/components/POS/ProductInfo.vue';
import ClientService from '../services/ClientService';
import ProductService from '../services/ProductService';
import PurchaseOrderService from '../services/PurchaseOrderService';

export default {
  name: 'pos',
  components: {
    PosHeader,
    ItemList,
    ProductInfo,
    Payment,
    Quotation,
  },
  computed: {
    ...mapGetters({
      productsList: 'productsList',
      itemsList: 'itemsList',
      authUserData: 'authUserData',
      selectedClient: 'selectedClient',
      frontItemsList: 'frontItemsList',
      savedQuotations: 'savedQuotationsAutocomplete',
      productsSearchType: 'productsSearchType',
    }),
    itemsListCount() {
      return this.itemsList.filter((x) => x.isGift !== 1).length;
    },
  },
  data: () => ({
    selectedSavedQuotation: null,
    showSavedQuotationsAutocomplete: false,
  }),
  methods: {
    async getSavedQuotationData() {
      const id = this.selectedSavedQuotation;
      const data = await PurchaseOrderService.getSavedQuotationData(id);
      this.$store.dispatch('loadSavedQuotationItems', data);
    },
  },
  async mounted() {
    const { store } = this.authUserData.user_claims;

    this.$store.dispatch('clearItemsList');

    let payload = await ClientService.getClientAutocompleteData();
    this.$store.dispatch('setClientsAutocomplete', payload);

    if (this.productsSearchType === 'remote') {
      payload = await ProductService.getProductAutocompleteData('', 100, store, null);
      this.$store.dispatch('setProductsAutocomplete', payload);
    } else {
      payload = await ProductService.getProductAutocompleteData('', 100, store, null);
      this.$store.dispatch('setProductsAutocomplete', payload);
    }

    payload = await PurchaseOrderService.getSavedQuotationsAutocompleteData(null, store);
    this.$store.dispatch('setSavedQuotationsAutocomplete', payload);
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style scoped>
.global-container {
  padding: 0 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  padding-top: 1rem !important;
}

.total-items-label {
  font-size: 1.2rem;
  font-weight: 400;
}

.saved-quotations-ac {
  height: 36px;
}
</style>
