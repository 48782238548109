<template>
  <v-row>
    <v-col cols="12" sm="5">
      <v-row align="center" justify="center"></v-row>
      <v-col cols="12" sm="12">
        <v-img src="../assets/logo-ma.png" alt="" contain height="400"></v-img>
      </v-col>
    </v-col>
    <v-col cols="12" sm="7">
      <v-row align="center" justify="center">
        <v-col class="admin-text" cols="12" sm="12">
          <h2 class="text-center">
            <v-icon color="white">mdi-point-of-sale</v-icon>
            Punto de venta
          </h2>
        </v-col>
        <v-col cols="6" sm="12" md="8">
          <v-card class="elevation-12">
            <!-- LOGIN CARD TOOLBAR -->
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                <v-icon>
                  mdi-login
                </v-icon>
                Inicio de sesión
              </v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <!-- LOGIN CARD TOOLBAR -->

            <!-- LOGIN INPUT FORM -->
            <v-card-text>
              <v-form>
                <v-text-field outlined
                              v-model="username"
                              @change="onChangeUsername"
                              label="Usuario"
                              color="secondary"
                              name="login"
                              prepend-icon="mdi-account"
                              type="text"/>

                <v-autocomplete hide-details
                                outlined
                                v-model="storeId"
                                no-data-text="No encontrado..."
                                :items="stores"
                                item-text="label"
                                item-value="value"
                                color="secondary"
                                prepend-icon="mdi-store"
                                label="Sucursal">
                </v-autocomplete>

                <v-text-field outlined
                              v-if="showPassword"
                              v-model="password"
                              id="password"
                              class="mt-6"
                              label="Contraseña"
                              color="secondary"
                              name="password"
                              prepend-icon="mdi-lock"
                              type="password"
                              @keypress.enter="authUser"/>
              </v-form>
            </v-card-text>
            <!-- LOGIN INPUT FORM -->

            <!-- LOGIN BUTTONS -->
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="authUser()" color="secondary">Entrar</v-btn>
            </v-card-actions>
            <!-- LOGIN BUTTONS -->

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2';
import UserService from '@/services/UserService';
import MiscService from '@/services/MiscService';
import { TokenUtils } from '@/utils/TokenUtils';
import AuthService from '@/services/AuthService';

export default {
  name: 'login-card',
  props: {
    source: String,
  },
  data: () => ({
    username: '',
    password: '',
    storeId: null,

    stores: [],
  }),
  methods: {
    // TODO: MOSTRAR MENSAJE DE ERROR AL HACER LOGIN FALLIDO
    async authUser() {
      if (!this.username) {
        Swal.fire('Error', 'Ingresa el usuario', 'error');
        return;
      }
      if (!this.storeId) {
        Swal.fire('Error', 'Ingresa la sucursal', 'error');
        return;
      }
      if (!this.password) {
        Swal.fire('Error', 'Ingresa la contraseña', 'error');
        return;
      }

      const resp = await AuthService.auth(this.username, this.password, this.storeId);
      if (resp.error) {
        Swal.fire('Error', `No se puede iniciar sesión, ${resp.error}`, 'error');
        return;
      }
      TokenUtils.setToken(resp.token);
      const payload = TokenUtils.getJwtData();

      const store = this.stores.find((item) => item.value === this.storeId);
      const ticket = await MiscService.getTicket();
      const { identity: userId } = TokenUtils.getJwtData();
      const attributes = await UserService.getUserAttributes(userId);

      localStorage.setItem('ticket', JSON.stringify(ticket));
      localStorage.setItem('storeName', store.label);

      this.$store.dispatch('setAuthUserData', payload);
      this.$store.dispatch('setTicketData', ticket);
      this.$store.dispatch('setAttributes', attributes);

      this.$router.push('/pos');
    },
    async onChangeUsername() {
      this.stores = [];

      if (!this.username) {
        return;
      }

      const response = await UserService.getUserStoresByQuery(this.username);
      this.stores = response;
    },
  },
  computed: {
    showPassword() {
      return this.username && this.storeId;
    },
  },
};
</script>
<style scoped>
.admin-text {
  z-index: 1;
}

h2 {
  letter-spacing: 0.25rem;
  color: white;
  font-size: 2rem;
  font-weight: 300;
}
</style>
