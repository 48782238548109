<template>
  <v-row :class="{ active: item === itemToPreview, discountLine: hasDiscount }"
         @click.stop="previewItemInfo(item)"
         class="item-list-item"
         @dblclick.stop="emitDblClick(item)">
    <v-col cols="12" sm="1" class="pb-2 text-center">
      <p v-if="item.isGift !== 1" class="title">
        {{ item.quantity }}
      </p>
      <p v-else class="title">
        - {{ item.quantity }}
      </p>
    </v-col>
    <v-col class="pb-2" cols="12" sm="6">
      <p class="title fitext">
        <span class="gift-label" v-if="item.isGift">(PROMO) </span>
        {{ item.name }} - {{ item.model }} - {{ item.brand }}
      </p>
    </v-col>
    <v-col class="text-right pb-0 pr-0" cols="12" sm="2">
      <p v-if="hasDiscount && !showDiscountInLine" class="title">
        {{ item.baseCostWithDiscountStr }}
      </p>
      <p v-else-if="item.isGift !== 1" class="title">{{ item.baseCostStr }}</p>
      <p v-else class="title">-{{ item.costWithDiscountStr }}</p>
    </v-col>
    <v-col class="text-right pb-0 pr-0" cols="12" sm="2">
      <p v-if="hasDiscount && !showDiscountInLine" class="title">
        {{ item.costWithDiscountStr }}
      </p>
      <p v-else-if="item.isGift !== 1" class="title">{{ item.costStr }}</p>
      <p v-else class="title">-{{ item.costWithDiscountStr }}</p>
    </v-col>
    <v-col class="text-center px-0 pb-2" cols="12" sm="1">
      <v-btn v-if="!item.isGift" color="red" @click.stop="removeItem(item, $event)"
             x-small fab outlined>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
    <v-col v-if="hasDiscount && showDiscountInLine"
           cols="12" sm="7" class="pt-0">
      <p class="mb-0 pa-0">
        Descuento del producto
      </p>
    </v-col>
    <v-col v-if="hasDiscount && showDiscountInLine"
           cols="12" sm="3" class="text-right pr-0">
      <p class="mb-0">- {{ item.discountStr }}</p>
      <!-- <p v-if="item.isGift" class="mb-0">Gratis</p> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import fitext from 'fitext';

export default {
  name: 'item-list-item',
  props: ['item', 'index'],
  computed: {
    ...mapGetters({
      itemToPreview: 'itemToPreview',
      showDiscountInLine: 'showDiscountInLine',
      frontItemsList: 'frontItemsList',
    }),

    hasDiscount() {
      return !!this.item && this.item.calculatedDiscount > 0 && this.item.isGift !== 1;
    },
  },
  methods: {
    removeItem(item) {
      this.$store.dispatch('removeItemFromFrontList', item);
      this.$store.dispatch('calculateCombos');
    },
    previewItemInfo(item) {
      const payload = this.frontItemsList.find((x) => x.id === item.id);
      this.$store.dispatch('previewItemInfo', payload);
    },
    emitDblClick(item) {
      const payload = this.frontItemsList.find((x) => x.id === item.id);
      this.$store.dispatch('previewItemInfo', payload);

      this.$emit('clicked', item);
    },
  },
  mounted() {
    const fitable = document.getElementsByClassName('fitext');
    fitext(fitable, false);
  },
};
</script>

<style lang="less" scoped>
.item-list-item {
  border-bottom: 1px solid black;
  padding: 0;
  padding-top: 0.8rem;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    // green: ebffef
  }

  p.title {
    font-size: 1rem !important;
    font-weight: 400;
    margin: 0;
  }
}

.gift-label {
  color: #4ed289;
  font-weight: 500;
}

.active {
  background-color: #f0f0f0;
  // green: e6ffea
}

.fitext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: auto;
    white-space: normal;
  }
}

.discountLine {
  background-color: #4adcdc;
}
</style>
